import { formatDate, FulfillmentStatusToText } from '../../../helpers';

import { BaseModel } from './base.model';

export class OrderFulfillmentModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get order() {
    return this._data.order;
  }

  get tracking() {
    return {
      link: this._data.trackingUrl,
      number: this._data.trackingNumber,
    };
  }

  get carrier() {
    return this._data.trackingCompany;
  }

  get status() {
    return FulfillmentStatusToText[this._data.fulfillmentStatus] || this._data.fulfillmentStatus;
  }

  get delivered() {
    return formatDate(this._data.updatedAt);
  }

  includes(itemId: string) {
    for (const item of this._data.items) {
      if (item.orderItemId === itemId) {
        return true;
      }
    }

    return false;
  }

  get products() {
    return this._data.items.map((item: any) => {
      const orderItem = this.order.orderItems.find(orderItem => orderItem.sourceItemId === item.item.sourceItemId);
      const inventory = this.order?.inventory?.find(inventoryItem => inventoryItem.id === item.item.sourceItemId);

      const priceAfterDiscounts = (item.item.price - orderItem.discount) * item.quantity;

      return {
        ...item.item,
        sourceOrderId: this.order.sourceOrderId,
        currency: this.order.currency,
        photo: item.item.productImage,
        price: item.item.price,
        priceAfterDiscounts,
        quantity: item.quantity,
        inventory,
        selectedOptions: orderItem.selectedOptions,
        title: item.item.productName,
        validQuantity: item.quantity,
        refundedQuantity: orderItem.refundedQuantity,
      };
    });
  }

  get items() {
    return this._data.items;
  }
}
