import { BaseModel } from './base.model';
import { calculateItemRefund, creditedMap } from '../../../helpers';
import { CUSTOM, FLAT_RATE, REFUND } from '../../../constants';

export class ClaimItemModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get photo() {
    return this._data.imageUrl;
  }

  get creditStore() {
    if (this._data.creditStore) {
      return this._data.creditStore;
    }

    if (this.claim.isOta) {
      return false;
    }

    return creditedMap[this.claimType];
  }

  set creditStore(creditStore) {
    this._data.creditStore = creditStore;
  }

  get details() {
    return this._data.details;
  }

  get productImage() {
    return this.photo;
  }

  get claim() {
    return this._data.claim;
  }

  get order() {
    return this.claim.order;
  }

  get quantity() {
    return this._data.quantity;
  }

  get resolutionId() {
    return this._data.resolutionId;
  }

  get refundedQuantity() {
    return this._data?.orderItem?.refundedQuantity;
  }

  get validQuantity() {
    const quantity = this._data?.orderItem?.quantity;
    const refundedQuantity = this.refundedQuantity;
    const claimQuantity = this.quantity;
    const remainingQuantity = quantity - refundedQuantity;

    if (remainingQuantity === 0 || claimQuantity > remainingQuantity) {
      return remainingQuantity;
    }

    return claimQuantity;
  }

  get title() {
    return this._data.name;
  }

  get orderId() {
    return this._data.orderId;
  }

  get productName() {
    return this.title;
  }

  get variation() {
    return '';
  }

  get orderItem() {
    const items = this.claim.order.products || this.claim.order.orderItems || [];
    return (
      items.find(
        (item: any) =>
          item.sourceItemId === this._data.sourceItemId && item.sourceVariantId === this._data.sourceVariantId,
      ) || null
    );
  }

  get currency() {
    if (!this.claim.order.orderItems || this.claim.order.orderItems.length === 0) {
      return 'USD';
    }

    return this.claim?.currency || 'USD';
  }

  get price() {
    if (!this.claim.order.orderItems || this.claim.order.orderItems.length === 0) {
      return 0;
    }

    return Number(this.orderItem?.price) || 0;
  }

  get sku() {
    return this._data.sku;
  }

  get selectedOptions() {
    return this?.orderItem?.selectedOptions ?? [];
  }

  get images() {
    return this._data.images.filter((item: { url: string }) => /\.(jpg|jpeg|png|gif|bmp|webp|heic)$/i.test(item.url));
  }

  get videos(): { url: string }[] {
    return this._data.images.filter((item: { url: string }) => /\.(mp4|mov)$/i.test(item.url));
  }

  get issue() {
    return {
      quantity: this.quantity,
      details: this.details,
      issueType: this.claimType,
      images: this.images,
    };
  }

  get discount() {
    if (!this.claim.order.orderItems || this.claim.order.orderItems.length === 0) {
      return 0;
    }

    return Number(this.orderItem?.discount) || 0;
  }

  get name() {
    return this.title;
  }

  get sourceVariantId() {
    return this._data.sourceVariantId;
  }
  get priceAfterDiscounts() {
    if (!this.claim.order.orderItems || this.claim.order.orderItems.length === 0) {
      return 0;
    }
    const price = Number(this.orderItem?.price) - Number(this.orderItem.discount);

    return +price.toFixed(3);
  }

  get priceInDollars() {
    if (!this.claim.order.orderItems || this.claim.order.orderItems.length === 0) {
      return 0;
    }
    const price = (Number(this.orderItem?.price) - Number(this.orderItem.discount)) * this.exchangeRate;

    return price.toFixed(3);
  }

  get tax() {
    return parseFloat(this.orderItem?.tax ?? 0).toFixed(3);
  }

  get exchangeRate() {
    return this.claim.order.exchangeRate;
  }

  get claimType() {
    return this._data.claimType || 'Unknown';
  }

  set claimType(claimType) {
    this._data.claimType = claimType;
  }

  get quantityType() {
    return this._data?.quantityType;
  }

  set quantityType(quantityType) {
    if (quantityType !== CUSTOM) this._data.resolutionQuantity = +quantityType;
    this._data.quantityType = quantityType;
  }

  get reimbursementType() {
    return this._data?.reimbursementType;
  }

  set reimbursementType(reimbursementType) {
    this._data.reimbursementType = reimbursementType;
  }

  get sourceProductId() {
    return this._data.sourceProductId;
  }

  get resolutionRequested() {
    return this._data.resolutionRequested;
  }

  get resolutionQuantity() {
    return this._data.resolutionQuantity;
  }

  set resolutionQuantity(quantity) {
    this._data.resolutionQuantity = quantity > 0 ? +quantity : null;
  }

  set resolutionPercentage(percentage) {
    this._data.resolutionPercentage = +percentage;
  }

  set resolutionFinal(resolution) {
    this._data.resolutionFinal = resolution;
    if (!resolution) {
      this._data.resolutionQuantity = null;
      this._data.resolutionPercentage = 0;
      this._data.quantityType = null;
    }
  }

  get resolutionPercentage() {
    return this._data.resolutionPercentage;
  }

  get resolutionFlatAmount() {
    return this._data.resolutionFlatAmount;
  }

  set resolutionFlatAmount(flatAmount) {
    this._data.resolutionFlatAmount = +flatAmount;
  }

  get resolutionFinal() {
    return this._data.resolutionFinal;
  }

  get sourceItemId() {
    return this._data.sourceItemId;
  }

  get sourceOrderId() {
    return this._data.sourceOrderId;
  }
}
