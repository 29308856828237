import {
  ClaimsRestService,
  DispatchService,
  IdentityService,
  MetadataService,
  MerchantService,
  MacrosService,
  BillingRestService,
  PlatformsService,
  OrdersRestService,
} from '~/support/services';

export default function useGateway() {
  const baseUrl = useRuntimeConfig().public.gatewayBaseUrl as string;

  const dispatch = new DispatchService(baseUrl);
  const identity = new IdentityService(baseUrl);
  const merchant = new MerchantService(baseUrl);
  const claims = new ClaimsRestService(baseUrl);
  const metadata = new MetadataService(baseUrl);
  const macros = new MacrosService(baseUrl);
  const billing = new BillingRestService(baseUrl);
  const platforms = new PlatformsService(baseUrl);
  const orders = new OrdersRestService(baseUrl);

  return {
    billing,
    claims,
    dispatch,
    identity,
    macros,
    merchant,
    metadata,
    orders,
    platforms,
  };
}
