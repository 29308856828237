import { formatPriceCurrency } from '../helpers/claims-orders.helper';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export function findRevenueShare(plans, date: Date) {
  return plans.find(plan => plan.voidedAt === null || dayjs(date).utc() < dayjs(plan.voidedAt).utc());
}

export function getBillAmount(bill, startDate: Date, endDate: Date) {
  const canceledWithinCycle = dayjs(bill.voidedAt) > dayjs(startDate) && dayjs(bill.voidedAt) < dayjs(endDate);

  if (!bill.voidedAt) {
    return formatPriceCurrency((bill.amount - bill.discount) / 100, 'USD');
  }
  if (bill.voidedAt && dayjs(bill.date) > dayjs(startDate) && canceledWithinCycle) {
    return formatPriceCurrency(0, 'USD');
  }
  if (dayjs(bill.voidedAt) > dayjs(endDate)) return formatPriceCurrency((bill.amount - bill.discount) / 100, 'USD');

  return formatPriceCurrency(-(bill.amount - bill.discount) / 100, 'USD');
}
