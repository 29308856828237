import { default as GatewayBase } from './gateway-base';

export type Label = {
  id: string;
  value: string;
  accountId: string;
  source?: string;
};
type TagAction = {
  action: 'add' | 'remove';
  storeId: string;
  subjectId: string;
  tagId: string;
  userDisplayName?: string;
  userId?: string;
};

export default class MetadataService extends GatewayBase {
  get notes() {
    return {
      create: this.createNote,
      get: this.getNotes,
    };
  }

  get metadata() {
    return {
      add: this.addMetadata,
      get: this.getMetadata,
    };
  }

  get tags() {
    return {
      create: this.createTag,
      list: this.getTagsByAccountId,
      query: this.queryTagsByAccountId,
      subject: {
        add: this.addTagToSubject,
        get: this.getTagsBySubjectId,
        remove: this.removeTagFromSubject,
        update: this.updateTagsFromSubject,
      },
    };
  }

  private getTagsBySubjectId = (subjectId: string) => {
    return this.client.get<Label[]>(`v1/subjects/${subjectId}/labels`);
  };

  private addTagToSubject = (input: {
    subjectId: string;
    tagId: string;
    storeId: string;
    userDisplayName?: string;
    userId?: string;
  }) => {
    return this.client.post(`v1/subjects/${input.subjectId}/labels/${input.tagId}`, {
      storeId: input.storeId,
      userDisplayName: input.userDisplayName,
      userId: input.userId,
    });
  };

  private removeTagFromSubject = (subjectId: string, tagId: string) => {
    return this.client.delete(`v1/subjects/${subjectId}/labels/${tagId}`);
  };

  private updateTagsFromSubject = (tags: TagAction[], accountId: string) => {
    return this.client.put(`v1/labels/${accountId}`, tags);
  };

  private getNotes = (id: string) => {
    return this.client.get(`v1/notes/${id}`);
  };

  private createNote = (
    id: string,
    input: { username: string; userId: string; threadId?: string; content: string; media?: any[] },
  ) => {
    return this.client.post(`v1/notes/${id}`, input);
  };

  private getMetadata = (subjectId: string) => {
    return this.client.get(`v1/metadata/${subjectId}`);
  };

  private addMetadata = (
    subjectId: string,
    input: { key: string; value: string; addedByUserId?: string; addedByUsername?: string },
  ) => {
    return this.client.post(`v1/metadata/${subjectId}`, input);
  };

  private queryTagsByAccountId = (accountId: string, query: string) => {
    return this.client.get<Label[]>(`v1/labels/${accountId}`, {
      params: {
        q: query,
      },
    });
  };

  private getTagsByAccountId = (accountId: string) => {
    return this.client.get<Label[]>(`v1/labels/${accountId}`);
  };

  private createTag = (accountId: string, input: { name: string; source?: string }) => {
    return this.client.post<Label>(`v1/labels/${accountId}`, input);
  };
}
