<template>
  <div class="w-full cursor-pointer text-typography-disabled text-xs select-none mb-4" @click="handleSearchClick">
    <div class="w-full bg-neutral-100 h-8 rounded outline-none flex items-center justify-between py-2 px-3">
      <div class="flex items-center gap-2">
        <MagnifyingGlassIcon
          class="text-typography-primary"
          :class="{
            'w-[18px] h-[18px] justify-center': collapsed,
            'w-[13px] h-[13px]': !collapsed,
          }"
        />
        <span v-if="!collapsed">Search</span>
      </div>
      <div v-if="!collapsed">⌘K</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';

import { useApplicationStore } from '~/store/application';

const store = useApplicationStore();
const collapsed = inject('collapsed', true);

function handleSearchClick() {
  store.openSearchModal();
  store.closeMenu();
}
</script>
